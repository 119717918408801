import { createTheme } from '@mui/material/styles';
import * as Colors from '@mui/material/colors';

import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils';
import theme_ from './CurrentTheme';

// call this function at the root of the application
// ClassNameGenerator.configure((componentName) =>
//     componentName.replace('Mui', ''),
// );

const muiTheme = createTheme({
    spacing: theme_.muiSpacing,

    palette: {
        mode: 'dark', //'light',
    },


    typography: {
        h1: {
            fontSize: '3rem',

            // '@media (min-width:600px)': {
            //     fontSize: '1.5rem',
            // },
            // [theme.breakpoints.up('md')]: {
            //   fontSize: '2.4rem',
            // },
        },

        h2: {
            fontSize: '2.6rem',
        },

        h3: {
            fontSize: '2.2rem',
        },

        h4: {
            fontSize: '1.6rem',
        },

        //fontSize: 12,
    },

    components: {
        // MuiFormControl: {
        //     styleOverrides: {
        //         root: {
        //             height: '36px',
        //         },
        //     }
        // },

        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             height: '36px',
        //         },
        //     }
        // },

        // MuiTypography: {
        //     styleOverrides: {
        //         root: {
        //             "& h1": {
        //                 fontSize: 'small',
        //                 color: "blue",
        //             }
        //         },                
        //     }
        // },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    //color: theme_.dialogTextColor,
                }
            }
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 1,
                    //backgroundColor: 'red',
                }
            }
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(2n)": { 
                        backgroundColor: theme_.tableOddRowColor,
                    }
                }
            }
        },

    },
    
    // root: {
    // },
    // .MuiDataGrid-row.Mui-odd {
    //     background-color: aliceblue;
    //   }
});

export default muiTheme;
