import React from 'react';

import styled from 'styled-components';

import * as FT from './FormTypes';

import FormGridItem from './FormElements/FormGridItem';
import InputTextAreaElement from './FormElements/FormInputTextAreaComponent';
import { SxPropsParam } from 'ui/SxPropsUtil';

const HiddenUsernameInput = styled.textarea({
    visibility: 'hidden',
    height: 0,
    margin: 0,
    padding: 0,
    borderWidth: 0,
    row: 10
});


//
// Linear input fields
//
export interface InputTextAreaProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps, FT.DataFieldsProps {
    maxRows?: number;
    minRows?:  number;
    label: string;
    id: string;
    type?: string;
    placeholder?: string | boolean;
    sx?:SxPropsParam //?
    
}


export function InputTextArea(props: InputTextAreaProps) {
    const [field, setField] = React.useState<FT.InputFormField | null>(null);

    React.useEffect(() => {
        if (!props.ngHidden) {
            let field = props.form.registerField(props.id, props.label, 'text', props);
            setField(field);
        }
    }, []);

    //console.debug(props.label, props.value);

    // if (props.ngHidden) {

    //     return <HiddenUsernameInput type='text'/>
    // }

    return <FormGridItem {...props}> <InputTextAreaElement field={field} {...props}/></FormGridItem>;
}
