import React from 'react'

import {useNavigate} from 'react-router-dom'

import {SxPropsParam} from 'ui/SxPropsUtil'

import theme_ from 'theme/CurrentTheme'
import {DesignNavButton} from './DesignNavButton'

interface AuthButtonProps {
    label?: string
    tlabel?: string // Translated label
    href?: string
    onClick?: () => void
    width?: number
    height?: number
    sx?: SxPropsParam
}

export function AuthButton(props: AuthButtonProps) {
    const navigate = useNavigate()

    const sx = props.sx![0]!
    const width = sx.width ?? theme_.authButtonSize.w
    const height = sx.height ?? theme_.authButtonSize.h

    const onClick = React.useCallback(() => {
        if (props.href) {
            navigate(props.href)
        } else if (props.onClick) {
            props.onClick()
        }
    }, [])

    // Designed component
    return (
        <DesignNavButton
            label={props.label}
            tlabel={props.tlabel}
            width={width}
            height={height}
            paperWidth={height}
            fontSize={theme_.authButtonFontSize}
            onClick={onClick}
            sx={props.sx}
        />
    )
}

// const ButtonElement = muiStyled('button')({
//     borderStyle: 'none',
//     backgroundColor: 'transparent',
//     padding: 0,
//     ...theme_.cssButtonHover(),
//     ...theme_.cssButtonShadow(),
// })
