import React from "react";
import Helmet from "react-helmet";
import {useTranslation} from 'react-i18next';


export interface HeaderProps {
    title?: string;
    ttitle?: string;
};

export default function HtmlHeader(props: HeaderProps) {
    const [t] = useTranslation();
    const [title] = React.useState(props.title ? t(props.title) : props.ttitle ?? 'BPF');

    return <Helmet>
        <title>{title}</title>
    </Helmet>;
}
