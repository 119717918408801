import React from "react"
import * as Api from "api/api"


// //import * as Page from "components/PageContainer"
// import * as Form from "components/PageInputFormObsolete"
import PageContents from "components/PageContents"
// import { validateEmail } from "lib/validate"
import * as Form from 'components/Form'
import * as AuthApi from '../../api/auth'
import { ApiUser } from "api/users"

import session_ from 'api/session'
import { margin, textAlign } from "@mui/system"


// function setButtonBusy(buttonRef: React.MutableRefObject<HTMLButtonElement | null | undefined>, label: string) {
//     buttonRef.current!.disabled = true;
//     buttonRef.current!.innerText = label;
// }

// function setButtonReady(buttonRef: React.MutableRefObject<HTMLButtonElement | null | undefined>, label: string) {
//     buttonRef!.current!.disabled = false;
//     buttonRef!.current!.innerText = label;
// }

export default function ContactUsPage() {
    //     const buttonRef = React.useRef<HTMLButtonElement | null | undefined>();
    //     const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    //     const onSubmit = () => {

    //         setErrorMessage(undefined);


    //         // Email validation
    //         let email = document.getElementById('email') as HTMLInputElement;
    //         let emailValue = email.value.trim();

    //         if (emailValue === '') {
    //             alert('Email is required.');
    //             return;
    //         }
    //         if (!validateEmail(emailValue)) {
    //             alert('Incorrect email.');
    //             return;
    //         }

    //         let subject = document.getElementById('subject') as HTMLInputElement
    //         let subjectValue = subject.value.trim();

    //         if (subjectValue === '') {
    //             alert('There is no subject.');
    //             return;
    //         }

    //         let textArea = document.getElementById('textArea') as HTMLInputElement
    //         let textAreaValue = textArea.value.trim();

    //         if (textAreaValue === '') {
    //             alert('There is no message');
    //             return;
    //         }
    //         setButtonBusy(buttonRef, 'Working...');

    //         // AuthApi.contactUs(value, passwordValue).then((session) => {
    //         //     setButtonReady(buttonRef, 'Sign In');
    //         //     navigate('/');
    //         //     //history.replace('/');
    //         // }).catch(err => {
    //         //     setButtonReady(buttonRef, 'Sign In');
    //         //     setErrorMessage(err.message ?? 'Unknown error');
    //         // });

    //         // buttonRef.current!.style.visibility = "hidden";

    //     };


    const form = Form.useInputForm();
    // const [data, setData] =AuthApi.authenticate();

    const onSubmitContactUs = React.useCallback((evt: Form.InputFormEvent) => {

        Api.requestSession<any>('user', 'contact_us', evt.data).then(d => {
            console.log(evt.data)
            console.log(d)
            console.log(`modified count is ${d.modifiedCount}`)
        }).catch(err => console.log(err));

    }, [])

    const onSubmitSendMessage = React.useCallback((evt: Form.InputFormEvent) => {

        Api.requestShared<any>('user', 'send_message', evt.data).then(d => {
            console.log(evt.data)
            console.log(d)
            console.log(`modified count is ${d.modifiedCount}`)
        }).catch(err => console.log(err));

    }, [])



    return <PageContents title="Contact Us">

        {session_.isLoggedIn ? <>
            {/* <Page.PageDialogContents> */}

            <Form.PageInputForm title='Contact Us' onSubmit={onSubmitContactUs} form={form} size='md' >
                <Form.InputGroup label='Subject' form={form} />
                <Form.InputText label='Subject' id='subject' form={form} xsMax />

                <Form.InputGroup label='Message' form={form} />
                {/* <Form.InputText label='Message' id='message' form={form} xsMax /> */}
                <Form.InputTextArea maxRows={6}  minRows={3} type='text' required label='Message' id='content' form={form} xsMax sx={{resize:'none', width: '500px'}}/>
                {/* chi toxnum style grem */}
                {/* style={resize:'none'} */}

                <Form.SubmitButton label='Submit' form={form} xsMax size='lg' align="center" />
            </Form.PageInputForm>


            {/* </Page.PageDialogContents>  */}
        </> : <>
            <Form.PageInputForm title='Contact Us' onSubmit={onSubmitSendMessage} form={form} size='md' >
                {/*             
            <Form.InputGroup label='Email' form={form} />
            <Form.InputText label='Email' id='email' form={form} xsMax/> */}

                <Form.InputGroup label='Email' form={form} />
                <Form.InputText label='Email' id='senderEmail' autocomplete='username' validate="email" required form={form} xsMax />

                <Form.InputGroup label='Subject' form={form} />
                <Form.InputText label='Subject' id='subject' validate="not-empty" required form={form} xsMax />

                <Form.InputGroup label='Message' form={form} />
                {/* <Form.InputText label='Message' id='content' validate="not-empty" required form={form} xsMax /> */}
                <Form.InputTextArea maxRows={6}  minRows={3} type='text' required label='Message' id='content' form={form} xsMax />


                <Form.SubmitButton label='Submit'  form={form} xsMax size='lg' align="center" />

            </Form.PageInputForm>
        </>}
    </PageContents>


    // return <PageContents title="Contact Us">
    //     {/* <Page.PageDialogContents> */}

    //     <Form.PageInputForm title='Contact Us' form={form} size='md' >
    //         {/*             
    //         <Form.InputGroup label='Email' form={form} />
    //         <Form.InputText label='Email' id='email' form={form} xsMax/> */}

    //         <Form.InputGroup label='Subject' form={form} />
    //         <Form.InputText label='Subject' id='subject' form={form} xsMax />

    //         <Form.InputGroup label='Message' form={form} />
    //         <Form.InputText label='Message' id='message' form={form} xsMax />


    //         <Form.SubmitButton label='Submit' form={form} xsMax size='lg' align="center" />

    //     </Form.PageInputForm>


    //     {/* </Page.PageDialogContents>  */}
    // </PageContents>

}

