import Env from 'env';

import * as Geo from 'lib/geo';
import { makeSxProps, SxPropsParam } from 'ui/SxPropsUtil';


import * as Form from 'components/Form';
import { PageHeaderTheme, ThemeBorder } from './ThemeElements';

export class ThemeInstance {

    themeColor = '#ff0000';
    errorColor = '#ff0000';


    //scan!: ScanThemeInstance;

    // 
    muiSpacing = 8;


    // Page Layout
    pageMinWidth = Env.isDesktop ? 768 : 320;
    pageMinHeight = Env.isDesktop ? 768 : 480;

    pageHeaderHeight = Env.isMobile ? 60 : 100; //120;

    pageHorzMargin = Env.isDesktop ? this.muiSpacing * 1 : 0;
    pageTopMargin = Env.isDesktop ? this.muiSpacing * 1 : 0;
    pageBottomMargin = Env.isDesktop ? this.muiSpacing * 1 : 0;

    pageContentsHorzMargin = Env.isDesktop ? this.muiSpacing * 2 : 0;
    pageContentsTopMargin = Env.isDesktop ? this.muiSpacing * 2 : 0;
    pageContentsBottomMargin = Env.isDesktop ? this.muiSpacing * 2 : 0;

    pageBackgroundColor = '#ffffff';
    pageTextColor = '#ff0000';  // VS Code dark theme color
    dialogTextColor = '#ff0000';


    //homeBackgroundImage = '/images/home_ai_dark/dark_home_background.png';
    homeBackgroundImage: string | undefined = undefined; // = '/images/home_ai_dark/dark_home_background.png';

    
    //
    // Button styling
    //
    authButtonSize = new Geo.Size(120, 32);
    authButtonGap = this.muiSpacing;
    authGreetingWidth = 180;
    //authButtonFontSize: CssType.Property.FontSize = '12px';
    authButtonFontSize = 14;

    navButtonSize = new Geo.Size(152, 36);
    navButtonGap = this.muiSpacing * 2;
    navButtonTextColor = '#000000';
    navButtonFontSize = 16;

    smallInputWidth = 80;

    //
    // Page header styling
    //
    homePageHeaderTheme = new PageHeaderTheme();

    //
    // Dialog and Form elements
    //
    dialogBorderRadius = 4;
    dialogBorderWidth = 2;
    dialogBorderStyle = 'solid';
    dialogMessageTransform = 'translateY(-8vh)';

    dialogBorderColor!: string;
    dialogBackgroundColor!: string;


    cssDialogBorder() {
        return {
            borderRadius: this.dialogBorderRadius,
            borderColor: this.dialogBorderColor,
            borderWidth: this.dialogBorderWidth,
            borderStyle: this.dialogBorderStyle,
        }
    }


    //
    // Input elements
    //
    inputTextBackgroundColor = '#ffffff';
    inputReadonlyBackgroundColor = '#eeeeee';


    //
    // Display grid
    //
    tableRowHeight = 60;
    tableThumbnailWidth = 200;
    tableActionButtonWidth = this.tableRowHeight; //Env.isDesktop ? 90 : 80;
    tableActionButtonHeight = this.tableRowHeight;
    tableOddRowColor = '#f4f4f4';

    //
    // Drop zones
    //
    dropZoneBorder = new ThemeBorder({width: 1, color: '#ffffff', style: 'solid'});
    dropZoneOverBorder = new ThemeBorder({width: 4, color: '#ffffff', style: 'dashed'});


    processWindowResize(){ return this.onWindowResize() }

    onInit(){}
    onWindowResize(){ return false; }
    


    //
    // Return CSS objects
    //
    cssButtonShadow() {
        return {
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }
    }

    cssButtonHover() {
        return {
            "&:hover": {
                border: "2px solid #25afce66",
            }
        }
    }

    getFormButtonSize(size?: Form.FormSizeType) {
        switch(size) {
        case 'sm': return 80;
        case 'md': return 180;
        case 'lg': return 300;
        case 'xl': return 400;
        default: return '100%';
        }
    }

    //
    // Generic css functions
    //
    cssRect(rect: Geo.IRect, options?: {right?: boolean}) {
        return {
            //position: 'absolute',
            left: options?.right ? undefined : rect.x, 
            right: options?.right ? rect.x + rect.w: undefined,

            top: rect.y,
            width: rect.w,
            height: rect.h,
        };
    }

    sxRect(rect: Geo.IRect, options?: {right?: boolean}) {
        return makeSxProps(this.cssRect(rect, options) as SxPropsParam);
    }

    sxSize(size: Geo.ISize) {
        return makeSxProps({
            width: size.w,
            height: size.h,
        });
    }

    // sxRect(size: Geo.ISize) {
    //     return makeSxProps({
    //         width: size.w,
    //         height: size.h,
    //     });
    // }

    sxAbsoluteCenter() {
        return makeSxProps({
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        });
    }



    //
    // Debugging
    //
    debugBorder = new ThemeBorder({width: 1});

    cssDebugBorder() { return this.debugBorder.css(); }

    sxDebugBorder() {
        return makeSxProps(this.cssDebugBorder()  as SxPropsParam);
    }

    cssDebugBackground(): SxPropsParam {
        return {
            backgroundColor: '#ffff0044',
        };
    }

    sxDebugBackground() {
        return makeSxProps(this.cssDebugBackground());
    }


};
