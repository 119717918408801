import theme_ from 'theme/CurrentTheme'
import PageImage from 'components/PageImage'

export default function PageLogo() {
    const decor = theme_.homePageHeaderTheme.logo

    if (!decor.show) return <></>

    return (
        <PageImage
            width={decor.width}
            height={decor.height}
            src={decor.src!}
            sx={decor.sxProps()}
        />
    )
}
