import React from 'react';

import {useTranslation} from 'react-i18next';

import * as i18n from 'i18n';

import session_ from 'api/session';


interface LabelProps {
    title?: string;
    label?: string;
    tlabel?: string;
}

export function translateLabel(props: LabelProps, defLabel?: string) {
    if (props.label)
        return i18n.t(props.label);
        
    if (props.title)
        return i18n.t(props.title);

    if (props.tlabel)
        return props.tlabel;

    if (defLabel)
        return i18n.t(defLabel);

    return '';
}


export function useTranslateLabel(props: LabelProps, defLabel?: string) {
    const [t] = useTranslation();  // We need this to trigger rerender

    let label = translateLabel(props, defLabel) as string;

    return [label, t];
}

