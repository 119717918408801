import React from 'react';
import {Box, Container, Grid, Autocomplete, TextField, Skeleton} from '@mui/material';

import * as FT from '../FormTypes';

interface GridItemProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
}

interface Props extends GridItemProps {
    needLoading?: boolean;
    needBox?: boolean;
    children?: React.ReactNode;
}



export default function FormGridItem(props: Props) {
    const gridAtts = FT.getFormGridAtts(props);
    const gridSx = FT.getFormSxParams(props); //, {mt: 1}));
    const isSkeleton = (props.needLoading === undefined || props.needLoading === true) && props.form.isLoading;

    if (props.gridComponent === false) {
        if (isSkeleton) {
            return <Skeleton animation='wave'>
                <>{props.children}</>
            </Skeleton>
        } else {
            return <>{props.children}</>;
        }
    }

    if (isSkeleton) {
        return <Grid item {...gridAtts} sx={gridSx}>
            <Skeleton animation='wave'>
                {props.needBox ? <>
                    <Box sx={{
                        display:'flex', 
                        justifyContent: props.align,
                        height: '100%',
                        alignItems: 'center',
                    }}>
                        {props.children}
                    </Box>
                </> : <>
                    {props.children}
                </>}
            </Skeleton>
        </Grid>;
    }

    return <Grid item {...gridAtts} sx={gridSx}>
        {props.needBox ? <>
            <Box sx={{
                display:'flex', 
                justifyContent: props.align,
                height: '100%',
                alignItems: 'center',
            }}>
                {props.children}
            </Box>
        </> : <>
            {props.children}
        </>}
    </Grid>;
}
