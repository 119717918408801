import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { styled as muiStyled, experimental_sx as sx } from '@mui/material';

import {useTranslation} from 'react-i18next';


import { makeSxProps, SxPropsParam } from 'ui/SxPropsUtil';

import theme_ from 'theme/CurrentTheme';
import { DesignNavButton } from './DesignNavButton';


interface ButtonProps {
    show?: boolean,
    tag: string;
    label?: string;
    tlabel?: string;
    width: number,
    height: number,
    href?: string;
    current?: string;  // Current page tag
    onSelected?: (tag: string) => void;
    sx?: SxPropsParam;
}

export default function PageNavButton(props: ButtonProps) {
    const [t] = useTranslation();
    //const [label] = React.useState(props.label ? t(props.label) : props.tlabel ?? 'Error');
    const navigate = useNavigate();

    // const width = theme_.navButtonSize.w;
    // const height = theme_.navButtonSize.h;

    const handleClick = React.useCallback(() => {
        if (props.href) {
            navigate(props.href);
        }
    }, []);



    if (props.show === false)
        return <></>;

    // Designed component
    return <DesignNavButton 
        label={props.label} 
        tlabel={props.tlabel} 
        width={props.width} 
        height={props.height} 
        paperWidth={props.height}
        fontSize={theme_.navButtonFontSize}
        onClick={handleClick}
        sx={props.sx}
    />


    //return <ButtonElement onClick={handleClick}>{label}</ButtonElement>
}

const ButtonElement = muiStyled('button')({
    width: theme_.navButtonSize.w,
    height: theme_.navButtonSize.h,
    ...theme_.cssButtonShadow(),
    ...theme_.cssButtonHover(),
});

