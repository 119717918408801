import React from 'react';

import {Alert, Box} from '@mui/material';

import {useTranslation} from 'react-i18next';

import theme_ from 'theme/CurrentTheme';
import FormPageTitleImpl from './FormPageTitleImpl';
import FormImplBody from './FormImplBody';
import { computeFormAtts, FormImplProps } from './FormImpl';

export default function FormImplPage(props: FormImplProps) {
    const form = props.form;

    const [t] = useTranslation();

    const [messageSeverity, setMessageSeverity] = React.useState<'error' | 'success' | 'warning' | null>(null);
    const [message, setMessage] = React.useState<string | null>(null);
    //const [error, setError] = React.useState(form.error);

    React.useEffect(() => {
        const onRerender = () => {
            if (form.error) {
                setMessageSeverity('error');
                setMessage(form.error.message);
            } else if (form.messageText) {
                setMessageSeverity(form.messageSeverity);
                setMessage(form.messageText);
            } else {
                setMessage(null);
            }

            //setError(form.error);
        };

        form.pubsub.addListener(form.rerenderListenerId, onRerender);

        return () => form.pubsub.removeListener(form.rerenderListenerId, onRerender);
    }, []);

    const atts = computeFormAtts(props);

    return <Box 
        display='flex' 
        alignItems='center' 
        justifyContent='center'
        height={atts.formHeight}
    >
        <Box display='block' sx={{
            p: form.formPadding,
            ...theme_.cssDialogBorder(),
            backgroundColor: theme_.pageBackgroundColor,
        }}>
            <FormPageTitleImpl {...props}/>

            {message && messageSeverity && 
                <Alert severity={messageSeverity} sx={{
                    mt: 1,
                    mb: 2,
                }}>{t(message)}</Alert>
            }

            <FormImplBody formAtts={atts} {...props}>
                {props.children}
            </FormImplBody>
        </Box>
    </Box>;

}
