import React from "react"

import styled from 'styled-components'

import theme_ from 'theme/CurrentTheme'


import HtmlHeader from "ui/HtmlHeader"

import AuthHeader, { getAuthHeaderSize } from "./Header/AuthHeader"
import LangSelector from './Header/LangSelector'
import PageLogo from './Header/PageLogo'
import PageBanner from "./Header/PageBanner"
import PageNav from "./Header/PageNavBar"


interface PageHeaderProps {
    current?: string
    title?: string
}


export default function PageHeader(props: PageHeaderProps) {

    return <HeaderContainer>
        <HtmlHeader title={props.title} ttitle='Bug Path Finder'/>
        <PageLogo />
        <PageBanner />
        <LangSelector type='button'/>
        <AuthHeader />
        <PageNav current={props.current ?? 'home'}/>
    </HeaderContainer>
}

const HeaderContainer = styled.div({
    position: 'absolute',
    left: 0,
    right: 0,

    minWidth: theme_.pageMinWidth,
    height: theme_.pageHeaderHeight,

    //...theme_.styledDebugBorder(),
})
