import React from 'react';

import styled from 'styled-components';

import * as FT from './FormTypes';

import FormGridItem from './FormElements/FormGridItem';
import InputTextElement from './FormElements/FormTextInputComponent';

const HiddenUsernameInput = styled.input({
    visibility: 'hidden',
    height: 0,
    margin: 0,
    padding: 0,
    borderWidth: 0,
});


//
// Linear input fields
//
export interface InputTextProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps, FT.DataFieldsProps {
    label: string;
    id: string;
    type?: string;
    placeholder?: string | boolean;
    autocomplete?: FT.AutoCompleteType;
}


export function InputText(props: InputTextProps) {
    const [field, setField] = React.useState<FT.InputFormField | null>(null);

    React.useEffect(() => {
        if (!props.ngHidden) {
            let field = props.form.registerField(props.id, props.label, 'text', props);
            setField(field);
        }
    }, []);

    //console.debug(props.label, props.value);

    if (props.ngHidden) {
        const autocomplete = props.autocomplete ?? 'off';

        return <HiddenUsernameInput type='text' autoComplete={autocomplete}/>
    }

    return <FormGridItem {...props}><InputTextElement field={field} {...props}/></FormGridItem>;
}
