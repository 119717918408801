import React from 'react'

import {Box, Container, Typography} from '@mui/material'
import {styled as muiStyled, experimental_sx as sx} from '@mui/material'
import {makeSxProps, SxPropsParam} from 'ui/SxPropsUtil'

import PageImage from 'components/PageImage'

import theme_ from 'theme/CurrentTheme'
import {useTranslateLabel} from 'ui/TranslateLabel'

interface ButtonProps {
    width: number
    height: number
    paperWidth: number
    fontSize?: number | string

    label?: string
    tlabel?: string

    sx?: SxPropsParam

    onClick: () => void
}

export function DesignNavButton(props: ButtonProps) {
    const [label, t] = useTranslateLabel(props)

    const width = props.width // ?? theme_.authButtonSize.w;
    const height = props.height // ?? theme_.authButtonSize.h;
    const paperWidth = props.paperWidth //height;
    const glassWidth = width - paperWidth

    // Images behind the botton
    return (
        <Container sx={makeSxProps(props.sx)}>
            <PageImage
                width={glassWidth}
                height={height}
                src='/images/nav_button_glass.png'
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            />

            <PageImage
                width={paperWidth}
                height={height}
                src='/images/nav_button_paper.png'
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            />

            <Typography
                textAlign='center'
                sx={{
                    position: 'absolute',
                    left: 0,
                    width: glassWidth,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    fontSize: props.fontSize,
                    //fontSize: props.sx.fontSize ?? props.fontSize,
                    color: theme_.navButtonTextColor,
                }}
            >
                {label as string}
                {/* TODO: Fix new React type error */}
            </Typography>

            <ButtonElement
                onClick={props.onClick}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: width,
                    height: height,
                }}
            />
        </Container>
    )

    /*    
    <ButtonElement onClick={onClick} sx={[...makeSxProps(props.sx), {
        position: 'relative',
        width: width,
        height: height,
    }]}>

    </ButtonElement>;
*/

    /*
    // Images inside the botton
    return <ButtonElement onClick={onClick} sx={[...makeSxProps(props.sx), {
        position: 'relative',
        width: width,
        height: height,
    }]}>
        <PageImage width={width - paperWidth} height={height} src='/images/nav_button_glass.png' sx={{
            position: 'absolute',
            left: 0,
            top: 0,
        }}/>

        <PageImage width={paperWidth} height={height} src='/images/nav_button_paper.png' sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
        }}/>

        <Typography sx={{
            position: 'absolute',
            left: 0,
            right: paperWidth,
            transform: 'translateY(-50%)',
        }}>
            {label}
        </Typography>
    </ButtonElement>;
*/

    // Basic button element
    // return <ButtonElement onClick={onClick} sx={[...sxProps, {
    //     width: width,
    // }]}>{label}</ButtonElement>;
}

const ButtonElement = muiStyled('button')({
    borderStyle: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    ...theme_.cssButtonHover(),
    ...theme_.cssButtonShadow(),
})
