import * as CssType from 'csstype';

import { StandardCSSProperties, SystemStyleObject } from '@mui/system';
import * as Geo from 'lib/geo';

import { makeSxProps, SxPropsParam } from 'ui/SxPropsUtil';
import React from 'react';

interface IThemeDecor {
    show?: boolean;
    position?: CssType.Property.Position; //. SystemStyleObject;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    width?: number;
    height?: number;

    src?: string;  // For images

    // fontSize?: CssType.Property.FontSize;  // Texts
    // fontFamily?: CssType.Property.FontFamily;
}

export class ThemeDecor implements IThemeDecor {
    show?: boolean = false;
    position?: CssType.Property.Position; //. SystemStyleObject;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    width?: number;
    height?: number;
    
    src?: string;

    fontSize?: CssType.Property.FontSize;  // Texts
    fontFamily?: CssType.Property.FontFamily;

    constructor(i?: IThemeDecor) {
        if (i) {
            this.init(i);
        }
    }

    init(i: IThemeDecor) {
        Object.assign(this, i);
        this.show = i.show === false ? false : true;
    }

    sxProps() {
        let sxObj = {...this};

        sxObj.show = undefined;
        sxObj.src = undefined;

        return makeSxProps(sxObj);
    }
}

export class PageHeaderTheme {
    backgroundDecor = new Array<ThemeDecor>();
    bkTopLeftDecor = new ThemeDecor();
    bkTopRightDecor = new ThemeDecor();
    
    logo = new ThemeDecor();
    titleBanner = new ThemeDecor();
    titleBannerShort = new ThemeDecor();

    mainMenu = new ThemeDecor();

    loginButton = new ThemeDecor();
    logoutButton = new ThemeDecor();
    joinButton = new ThemeDecor();
    greetingButton = new ThemeDecor();

    langSelector = new ThemeDecor();


    navBar = new ThemeDecor();
    // navShow = true;
    // navAboutButton = new ThemeDecor();
    // navScansButton = new ThemeDecor();
    // navHomeButton = new ThemeDecor();

    // navRect = new Geo.Rect();
    // navButtonSize = new Geo.Size();

}


export class ThemeTableColumn {
    label?: string;
    tlabel?: string;
    width?: number;
}

export class ThemeTable {
    columns: ThemeTableColumn[] = [];
    rowHeight!: number;

}


export interface ThemeBorderParams {
    width?: number;
    radius?: number;
    color?: CssType.Property.Color;
    style?: CssType.Property.BorderStyle;
    boxSizing?: CssType.Property.BoxSizing;
}

export class ThemeBorder {
    width?: number;
    radius?: number;
    color?: CssType.Property.Color;
    style?: CssType.Property.BorderStyle;
    boxSizing?: CssType.Property.BoxSizing;

    constructor(i?: ThemeBorderParams) {
        if (i)
            Object.assign(this, i);
    }

    css() {
        let css: CssType.Properties = {} ; //Record<any>();
        if (!this.width || this.style === 'none')
            return css;

        css.borderWidth = this.width as CssType.Property.Width;
        css.borderRadius = this.radius as CssType.Property.Width;
        css.borderColor = this.color ?? '#ff0000'; // as CssType.Property.Width;
        css.borderStyle = this.style ?? 'solid';
        css.boxSizing = 'border-box';

        return css;
    }

    //| React.RefObject<any>//
    applyStyle(el: HTMLElement | undefined | null | React.RefObject<any>) {
        if (!el)
            return;

        let style: CSSStyleDeclaration;

        if (el instanceof HTMLElement) {
            style = el.style;
        } else {
            if (!el.current)
                return;
            style = el.current.style;
        }

        if (!this.width) {
            style.borderStyle = 'none';
            return;
        }

        style.borderWidth = `${this.width}px`;
        style.borderRadius = this.radius ? `${this.radius}px` : '0';
        style.borderColor = this.color ? this.color : '#ff0000';
        style.borderStyle = this.style ?? 'solid';
        //style.boxSizing = this.boxSizing ?? 'border-box';

    }
}
