import { validateEmail } from 'lib/validate';
import { InputFormContext } from '../FormContext';
import * as FT from '../FormTypes';

const assertField = (condition: any, message: string, field?: string, postfix?: string) => {
    if (!condition) {
        let text = field ?? '';
        if (text.length !== 0)
            text += ' ';
        text += message;
        throw new Error(text);
    }
};

export function prepareUpdateData(formCtx: InputFormContext, ev: FT.InputFormEvent)
{
    // for (let [id, field] of formCtx.registeredFields) {
    //     ev.fields[id] = field;
    // }
}

export function validateForm(formCtx: InputFormContext, ev: FT.InputFormEvent)
{
    let firstError: Error | undefined = undefined;
    let dataCount = 0;

    //console.debug(formCtx.registeredFields.size);

    for (let [id, field] of formCtx.registeredFields) {
        ev.fields[id] = field;

        try {
            //console.debug(field);

            switch (field.type) {
            case 'text': {
                field.inputElement = document.getElementById(field.id) as HTMLInputElement;
                let value = field.inputElement.value.trim();
                //field.value = field.inputElement.value.trim();
                //let value = field.value ?? '';

                //console.debug(field.value.length);

                if (field.required) {
                    assertField(value.length !== 0, "is required", field.name);
                }

                if (value.length !== 0) {
                    switch (field.validate) {
                    case 'email':  
                        assertField(validateEmail(value), 'Invalid email format');
                        break;
                    case 'off':
                    default: 
                        break;
                    }
                }

                const origValue = field.origValue ?? '';
                
                //console.debug(`${field.id}: ${value}, ${origValue}`);
                
                let skipValue = formCtx.formType === FT.FormType.Update && value === origValue;

                if (!skipValue) {
                    field.value = value;

                    if (field.isData) {
                        ev.data[field.id] = field.value;
                        ++dataCount;
                    } else {
                        ev.notData[field.id] = field.value;
                    }
                }

                break;
            }
            default:
                break;
            }
            //}
        }
        catch(err) {
            // ev.data = undefined;
            // ev.notData = undefined;
            //ev.error = err as Error;
            field.error = err as Error;
            if (!firstError)
                firstError = field.error;
            //formCtx.setError(field.error);
        }
    }

    if (firstError) {
        formCtx.setError(firstError);
        //formCtx.error = firstError;
    }

    return dataCount;
}

