import styled from 'styled-components';

import { styled as muiStyled, experimental_sx as sx } from '@mui/material';


import {Box} from '@mui/material';

import * as FT from './FormTypes';

import FormGridItem from './FormElements/FormGridItem';


interface Props extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps, FT.DataFieldsProps {
    src: string;
    width?: number;
    height?: number;
    alt?: string;
}

//const Image = styled('img')({
const Image = muiStyled('img')({
});

export function DisplayImage(props: Props) {


    return <FormGridItem {...props}>
        {props.form.isLoading ? 
            <Box sx={{
                width: props.width ?? props.height,
                height: props.height ?? props.width,
            }}>&nbsp;</Box> 
        :
            <Image src={props.src} alt={props.alt ?? props.src} sx={{
                width: props.width,
                height: props.height,
            }}/>
        }

    </FormGridItem>
}
