import React from 'react';
import {TextField, TextareaAutosize} from '@mui/material';

import {useTranslation} from 'react-i18next';

import * as FT from '../FormTypes';

import theme_ from 'theme/CurrentTheme';
import { InputTextAreaProps } from '../FormInputTextArea';


interface Props extends InputTextAreaProps {
    field: FT.InputFormField | null;
    //item: InputTextProps;  // Form item props
}


export default function InputTextAreaPropsElement(props: Props) {
    //const props = fieldProps.item;
    let propsValue = props.value ?? '';
    //const form = props.form;

    const [t] = useTranslation();

    // const [prevForm, setPrevForm] = React.useState(props.form);

    const [origValue, setOrigValue] = React.useState(propsValue);
    const [valueChanged, setValueChanged] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(propsValue);
    const [inputFocused, setInputFocused] = React.useState(false);
    const [inputColor, setInputColor] = React.useState<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'>('primary');


    const onValueChange = React.useCallback((v: string) => {
        setInputValue(v);
        if (props.field) {
            props.field.value = v;
        }

        if (!valueChanged && v !== origValue) {
            setValueChanged(true);
            setInputColor('success');
            setInputFocused(true);
        }
    }, []);



    // React.useEffect(() => {
    //     if (prevForm) {
    //         console.debug(props.id);
    //         prevForm.registeredFields[props.id].value = origValue;
    //         console.debug(`${props.id} => ${prevForm.registeredFields[props.id].value}`)
    //     }
    // }, [prevForm]);

    // if (prevForm !== form) {
    //     setPrevForm(form);
    // }

    if (!props.field)
        return <></>;


    if (propsValue !== origValue) {
        props.field.origValue = propsValue;
        props.field.value = propsValue;
        setOrigValue(propsValue);
        setInputValue(propsValue);
    }

    //console.debug(`${props.label} => ${props.value} => ${inputValue}`);


    let inputType = props.type ?? 'text';
    let width: string | number = '100%';
    let height: 'small' | 'medium' = (props.xs === 12 || props.xsMax)? 'medium' : 'small';

    // let autocomplete = props.autocomplete ?? 'off';

    let placeholder: string | undefined = undefined;

    if (props.placeholder === undefined) {
        placeholder = t('Enter') + ' ' + t(props.label);
    } else {
        if (props.placeholder === false) {
            placeholder = undefined;
        } else {
            placeholder = t(props.placeholder as string);
        }
    }

    // const placeholder = props.placeholder === undefined ? t('Enter') + ' ' + t(props.label) : (props.placeholder === false ? undefined : t(props.placeholder as string));

    let variant: 'outlined' | 'filled' = 'outlined';
    // if (props.readonly || props.displayonly)
    //     variant = 'filled';

    const backgroundColor = (props.readonly || props.displayonly)? theme_.inputReadonlyBackgroundColor : theme_.inputTextBackgroundColor;


    if (props.form.isLoading) {
        return <TextareaAutosize 
        id={props.id}
        style={{ width: 200, height: 300 }}
            maxRows = {props.maxRows ?? 5}
            minRows = {props.minRows ?? 1}
            // variant={variant}
            placeholder={placeholder} 
            // type={inputType}
            // size={height}
            // sx={{
            //     width: width,
            // }}
            // inputProps={{
            //     // autoComplete: autocomplete,
            //     readOnly: props.readonly || props.displayonly,
            // }}
        />;
    }


    return <TextareaAutosize 
        id={props.id}
        style={{ width: '100%', resize:'none' }}
        maxRows = {props.maxRows ?? 5}
        minRows = {props.minRows ?? 2}
        // label={t(props.label)}

        // variant={variant}
        placeholder={placeholder} 
        // type={inputType}
        // focused={inputFocused}
        value={inputValue}
        onChange={(e) => {
            onValueChange(e.target.value);
        }}
        color={inputColor}
        // size={height}  // TODO: figure out width and heights
        // sx={{
        //     width: width,
        //     backgroundColor: backgroundColor,
        // }}

        // inputProps={{
        //     // autoComplete: autocomplete,
        //     readOnly: props.readonly || props.displayonly,
        // }}
    />
}
