import { FormButtonComponent } from './FormElements/FormButtonComponent';
import * as FT from './FormTypes';

export interface FormButtonProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
    label: string;
    type?: string;
    busyLabel?: string;
    id?: string;
    onClick?: () => void;
};


export function FormButton(props: FormButtonProps) {
    return FormButtonComponent(props);
}

export function SubmitButton(props: FormButtonProps) {

    let p = {...props};

    if (p.mt === undefined)
        p.mt = 1;

    p.type = 'submit';
    if (!p.size)
        p.size = 'lg';

    return FormButtonComponent(p);
}
