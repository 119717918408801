import React from 'react';
import * as FT from '../FormTypes';

export interface FormImplProps extends FT.FormProps {
    formType: FT.FormType;
    //formProps: FT.FormProps;
    children?: React.ReactNode;
}

export interface FormComputedAtts {
    gridWidth: number;
    formHeight: number | string;
}

export function computeFormAtts(props: FormImplProps): FormComputedAtts {
    //let props = compProps.formProps;

    // TODO: move to theme
    let gridWidth = 300;
    let formHeight = '100%';

    switch (props.size) {
    case 'sm': 
        gridWidth = 300; 
        formHeight = '80%';
        break;
    case 'md': 
        gridWidth = 500; 
        formHeight = '90%';
        break;
    case 'lg': 
        gridWidth = 800; 
        break;

    case 'xl': 
        gridWidth = 1600; 
        break;
    default: break;
    }

    return {
        gridWidth: gridWidth,
        formHeight: formHeight,
    }
}
