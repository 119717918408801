import device from 'current-device'

import * as Path from 'lib/path'
import {assertNotEmpty} from 'lib/assert'

export default class Env {
    //
    // Development/production modes
    //
    static readonly nodeEnv = process.env.NODE_ENV
    static readonly isDev = process.env.NODE_ENV === 'development'
    static readonly isProd = !Env.isDev

    //
    // Convenience functions
    //
    static getOption(name: string, def: string = '' ): string {
        let val = process.env[name]
        return val ?? def
    }

    static requireOption(optName: string): string {
        let val = process.env[optName]
        assertNotEmpty(val, `.env missing: ${optName}`)
        return val!
    }

    static getAppOption(optName: string, def: string = ''): string {
        return Env.getOption('REACT_APP_' + optName, def)
    }

    static requireAppOption(optName: string): string {
        return Env.requireOption('REACT_APP_' + optName)
    }


    //
    // Assets Locations
    //
    //static readonly publicUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '/';
    static readonly publicUrl = Env.getOption('PUBLIC_URL', '/')
    static readonly publicUrlImages = Path.makeFilePathRoot(Env.publicUrl, 'images')
    static makePublicImageUrl(...comps: string[]): string {
        return Path.makeFilePathRootArr(Env.publicUrlImages, comps)
    }

    //
    // Device information
    //
    static readonly isMobile = device.mobile()
    static readonly isDesktop = device.desktop()
    static readonly isTablet = device.tablet()

    static readonly isLandscape = device.landscape()
    static readonly isPortrait = device.portrait()

    //
    // Server API locations and setup
    //
    static readonly apiRoot = Env.getAppOption('API_ROOT', '/api/v1')
    static readonly apiScan = Path.makeFilePath(Env.apiRoot, 'scan')
    static readonly apiRecog = Path.makeFilePath(Env.apiRoot, 'recog')

    // //
    // // Data Location
    // //
    static readonly dataScanS3Bucket = Env.getAppOption('SCANS_S3_BUCKET') // REACT_APP_SCANS_S3_BUCKET
    static readonly dataScanS3Region = Env.getAppOption('SCANS_S3_REGION')
    static readonly dataScanS3Dir = Env.getAppOption('SCANS_S3_DIR')

    static makeScanDataS3Root(scanId: string): string {
        let s3 = `https://${Env.dataScanS3Bucket}.s3.${Env.dataScanS3Region}.amazonaws.com`
        return Path.makeFilePath(s3, Env.dataScanS3Dir, scanId)
    }

    //
    // Service worksers
    //
    //static readonly serviceWorkerUrlRoot = process.env.REACT_APP_SERVICE_WORKER_URL_ROOT!;
}
