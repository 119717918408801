import Env from 'env';
import session_ from './session';

export class EndPoint {
    url: string;
    type?: string;

    constructor(_url: string) {
        this.url = _url;
    }
}

export type ApiParams = Record<string, string>;
export interface RequestOoptions {
    body?: boolean;
}

export interface FetchParams {
    limit?: number;  // Maximum number of records to retrieve
    
    page?: number;

    orderBy?: string;
    orderAscending?: boolean;
}

export class FetchStatus {
    code = 0;  // status code: 0 - pending, 1 - success, 2 > error code
    
    data?: string;  // status data: depends on the code
    message?: string;  // status message: usually error or progress

    count?: number;  // Number of recortds returned
    limit?: number;  // limit: 0 - no limit
    pages?: number; // total pages: 0 - not paged
    page?: number;  // current page
}

export class FetchResponse<T> {
    status?: FetchStatus;
    data?: Array<T>;

    //getStatusCode(): number { return this.status ? this.status.code : 2; }
    getStatus(): FetchStatus { return this.status ?? {code: 2}; }
    getData<DataType>(): DataType { return this.data! as unknown as DataType; }
}


export class UpdateStatus {
    code = 0;
    message?: string;
}

export type FetchCallback<Data> = (status: FetchStatus, data?: Data) => void;
export type UpdateCallback = (status: UpdateStatus) => void;

//function FetchCallback<Data>(status: FetchStatus, data: Data) {
//}
   


export function makeEndPoint(...comps: string[]) {
    return comps.join('/');
}

// export function makeApiRootMapBox(scanId: string) {  
//     return makeEndPoint(Env.apiRootMapBox, scanId);
// }

export function makeRootScan(scanId: string) {  
    return makeEndPoint(Env.apiScan, scanId);
}

export function makeImageEndpoint(ep: EndPoint, name: string) {
    return fmtNameEndpoint(ep.url, name);
    //let url = ep.url.replaceAll("{:name}", name);
    //let url = ep.url.replace(new RegExp('{:name}', 'g'), name);
    //return url;
}

//
// Various Formatting functions
//
export function fmtNameEndpoint(s: string, name: string) {
    // We'll use replaceAll once we figure polyfills with TypeScript
    //let url = ep.url.replaceAll("{:name}", name);
    return s.replace(new RegExp('{:name}', 'g'), name);
}

function makeError(res?: Response): Error {
    return new Error(res ? res.statusText : "Invalid response");
}

export function makeApiUrl(group: string, command: string, params?: any) {
    let url = makeEndPoint(Env.apiRoot, group, command);

    if (params) {
        let query = new URLSearchParams(params).toString();

        if (query.length > 0) {
            url += '?' + query;
        }
    }
    return url;
}

function makeApiUrlToken(group: string, command: string, token: string, params?: ApiParams) {
    let url = makeEndPoint(Env.apiRoot, group, command);

    url += '?token=' + token;

    if (params) {
        let query = new URLSearchParams(params).toString();

        if (query.length > 0) {
            url += '&' + query;
        }
    }
    return url;
}

function makeApiHeaders() {
    let headers = new Headers();
    return headers;
}

function processFetch<T>(url: string, headers: Headers, body?: any) {

    let req = new Request(url, {
        method: body ? 'POST' : 'GET',
        headers: headers,
        //body: body
        body: JSON.stringify(body)
    });

    return new Promise<T>((resolve, reject) => {
        fetch(req).then(res => {
            if (!res.ok){
                return reject(makeError(res));
            }
            
            res.json().then(json => {

                if (json.error) {
                    //console.debug(json.error);

                    if (json.error.code && +json.error.code === 501) {
                        session_.authenticate();
                    }
                    return reject(new Error(json.error.message ?? 'Unknown Error'));
                    // return reject(json.error.message ?? 'Unknown Error');
                }
                resolve(json as T);
            }).catch(reject);

        }).catch(reject);
    });
}


export function requestShared<T>(group: string, command: string, params?: ApiParams): Promise<T> {
    let url = makeApiUrl(group, command, params);
    let headers = makeApiHeaders();
    return processFetch(url, headers);
}


export function requestSession<T>(group: string, command: string, params?: ApiParams, body?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (!session_.isLoggedIn)
            return reject(new Error("User not logged in"));

        let url = makeApiUrlToken(group, command, session_.token, params);
        let headers = makeApiHeaders();
        if (body)
            headers.append('Content-Type', 'application/json');
        if(body)console.debug(body)
        processFetch<T>(url, headers, body).then(resolve).catch(reject);
    });
}

export function requestAdmin<T>(group: string, command: string, params?: ApiParams): Promise<T> 
{
    return new Promise<T>((resolve, reject) => {
        if (!session_.isAdmin)
            return reject(new Error("User is not administrator"));

        let url = makeApiUrlToken(group, command, session_.token, params);
        let headers = makeApiHeaders();
        processFetch<T>(url, headers).then(resolve).catch(reject);
    });
}

export function requestBlob(ep: string) {

    let url = makeEndPoint(Env.apiRoot, ep);

    //console.log(url);  // TODO: debug

    let headers = new Headers();

    let req = new Request(url, {
        headers: headers,
    });

    // TODO: remove Blob
    return new Promise<Blob>((resolve, reject) => {

        fetch(req).then(res => {
            if (!res.ok)
                return reject(makeError(res));

            res.json().then(json => 
                resolve(json)
            ).catch(reject);

        }).catch(reject);
    });


}

