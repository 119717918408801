import React from 'react';
import {Alert, Box, Container, Grid, Typography} from '@mui/material';

import { styled as muiStyled, experimental_sx as sx } from '@mui/material';

import {useTranslation} from 'react-i18next';


import * as FT from '../FormTypes';
import { validateForm } from './FormValidate';


import { FormComputedAtts } from './FormImpl';


export const FormElement = muiStyled('form')({}
    //sx({
        //padding: 2,
        //...currentTheme.getDialogBorderStyle(),
    //}),
);

interface FormImplProps extends FT.FormProps {
    //formType: FT.FormType;
    //formProps: FT.FormProps;
    formAtts: FormComputedAtts;

    //children?: React.ReactNode;
}


export default function FormImplBody(props: FormImplProps) {
    const form = props.form;

    const [t] = useTranslation();

    const handleSubmit = React.useCallback((evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        form.clearError(); //error = undefined;

        let ev = new FT.InputFormEvent();
        let dataCount = validateForm(form, ev);

        if (form.formType === FT.FormType.Update && dataCount === 0) {
            form.setError("No changes made.");
        }
        // if (!form.error) {
        // }
        props.onSubmit && props.onSubmit(ev);

        // Reset orig values
        for (let [_, field] of form.registeredFields) {
            field.origValue = field.value;
        }
    }, []);

    //const atts = computeFormAtts(implProps);
    const atts = props.formAtts;
    //console.debug(form.formId);

    return <FormElement id={form.formId} onSubmit={handleSubmit} 
    sx={{
        m: 0, //form.formMargin,
        //p: 0, //form.formPadding,
        //p: form.gridMargin,
    }}>
        {props.gridComponent === false ?
            <>{props.children}</>
        :
            <Grid container spacing={props.form.gridSpacing} display='flex' sx={{
                width: atts.gridWidth,
            }}>
                {props.children}
            </Grid>
        }
    </FormElement>;
    
}

