import { SxProps, Theme } from '@mui/material/styles'
import { SystemStyleObject } from '@mui/system'

export type SxPropsParam = SxProps<Theme>
export type SxObject = SystemStyleObject<Theme>
//export type SxObjectArray = Array<SxObject | boolean>

export function makeSxProps(sx?: SxPropsParam) {
    return  sx ? (Array.isArray(sx) ? sx : [sx]) : []
}

export function getSxObject(sx?: SxPropsParam): SxObject | undefined {
    if (!sx)
        return undefined

    if (Array.isArray(sx)) {
        if (sx.length > 0)
            return sx[0] as SxObject;
        return undefined  // TODO: work if there are many elements, or alwayr return array
    }

    return sx as SxObject
}
