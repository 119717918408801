
export function assertAlertThrow(cond: any, message: string) {
    if (!cond) {
        alert(message); // TODO: figure out better error handling
        throw new Error(message);
    }
    return cond!;
}

export function assertThrow(cond: any, message: string) {
    if (!cond) {
        throw new Error(message);
    }
    return cond!;
}

export function assertNotEmpty(cond: string | Array<any> | undefined | null, message: string) {
    return assertAlertThrow(cond && cond.length > 0, message);
}
