import theme_ from 'theme/CurrentTheme'

import PageImage from 'components/PageImage'

export default function PageBanner() {
    const th = theme_.homePageHeaderTheme

    return (
        <>
            {th.titleBanner.show && (
                <PageImage
                    width={th.titleBanner.width}
                    height={th.titleBanner.height}
                    src={th.titleBanner.src}
                    sx={th.titleBanner.sxProps()}
                />
            )}

            {th.titleBannerShort.show && (
                <PageImage
                    width={th.titleBannerShort.width}
                    height={th.titleBannerShort.height}
                    src={th.titleBannerShort.src}
                    sx={th.titleBannerShort.sxProps()}
                />
            )}
        </>
    )
}
