import React from 'react';
import FormImplPage from './FormElements/FormImplPage';

import * as FT from './FormTypes';


export function PageUpdateForm(props: FT.FormProps) {
    return <FormImplPage formType={FT.FormType.Update} {...props}>{props.children}</FormImplPage>;
}


export function PageInputForm(props: FT.FormProps) {
    return <FormImplPage formType={FT.FormType.Input} {...props}>{props.children}</FormImplPage>;
}
