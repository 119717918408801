import React from 'react';
import { InputFormContext } from './FormContext';

export type FormSizeType ='sm' | 'md' | 'lg' | 'xl';

export type AutoCompleteType = 'off'
    | 'username' | 'email'
    | 'new-password' | 'current-password'
    | 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname'
    | 'tel'
    ;


export interface FormPropsBase {
    form: InputFormContext;
    size?: FormSizeType;
    align?: 'left' | 'center' | 'right';
    gridComponent?: boolean;
}
    

export interface FormInputGridProps {
    xs?: number | 'auto' | true;  // Grid xs parameter
    
    xsMax?: boolean;
    xsHalf?: boolean;
    xsThird?: boolean;
    xsThird2?: boolean;
    xsQuarter?: boolean;

    md?: number;
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
}

export function getFormGridAtts(props: FormInputGridProps, defaultProps?: FormInputGridProps) {
    let xs = defaultProps?.xs;

    if (props.xs)
        xs = props.xs;
    else if (props.xsMax)
        xs = 12;
    else if (props.xsHalf)
        xs = 6;
    else if (props.xsThird)
        xs = 4;
    else if (props.xsThird2)
        xs = 8;
    else if (props.xsQuarter)
        xs = 3;

    return {
        xs: xs,
        md: props.md ?? defaultProps?.md,
    }
}

// xs parameters
export interface InputSxProps {
    m?: number;  // margin
    mt?: number; // margin top
    ml?: number;  // margin left
    mr?: number;
    mb?: number;
    //xs?: number | 'auto' | true;  // Grid xs parameter

    width?: number;
    height?: number;
}

export function getFormSxParams(props: InputSxProps, defaultProps?: InputSxProps) {
    return {
        m: props.m ?? defaultProps?.m,
        mt: props.mt ?? defaultProps?.mt,
        ml: props.ml ?? defaultProps?.ml,
        mr: props.mr ?? defaultProps?.mr,
        mb: props.mb ?? defaultProps?.mb,
        height: props.height ?? defaultProps?.height,
    }
}

export interface DataFieldsProps {
    required?: boolean;
    validate?: InputValidateType;
    data?: boolean;
    readonly?: boolean;
    displayonly?: boolean;
    value?: string;
    ngHidden?: boolean;
}



export type InputFormDataItem = Record<string, string>;

export class InputFormEvent {
    //error?: Error | null | undefined;
    data: InputFormDataItem = {};
    notData: InputFormDataItem = {};
    fields: Record<string, InputFormField> = {};
    // data: InputFormDataItem[] = [];
    // notData: InputFormDataItem[] = [];
}

export type InputFieldType = 'text' | 'checkbox';

export type InputValidateType = 'off' | 'not-empty' | 'integer' 
    | 'email' 
    | 'password' | 'new-password' 
    | 'tel';


export class InputFormField {
    id: string;
    name: string;
    type: InputFieldType;
    isData = true;  // if false the item won't be submitted in the form data
    required = false;
    validate: InputValidateType = 'off';
    error?: Error;
    inputElement?: HTMLInputElement;
    origValue?: string; // | number | boolean;
    value?: string; // | number | boolean;

    constructor(id: string, name: string, type: InputFieldType) {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}

export enum FormType {
    Input,
    Update,
}

export interface FormProps extends FormPropsBase {
    show?: boolean;
    title?: string;
    
    onClose?: () => void;
    onSubmit?: (evt: InputFormEvent) => void;
    submitLabel?: string;

    error?: Error | null;
    children?: React.ReactNode;
    //formType?: FormType;
};
