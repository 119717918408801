import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Button} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Env from 'env'

import * as Geo from 'lib/geo'

import session_ from 'api/session'
import * as AuthApi from 'api/auth'
import theme_ from 'theme/CurrentTheme'

import {AuthButton} from '../Buttons/AuthButton'
import PageMainMenu from './PageMainMenu'

export function getAuthHeaderSize() {
    let size = new Geo.Size()

    if (Env.isMobile) {
        return Geo.makeSize(theme_.pageHeaderHeight, theme_.pageHeaderHeight)
    }

    if (session_.isLoggedIn) {
        return Geo.makeSize(theme_.authGreetingWidth, theme_.authButtonSize.h)
    }

    const buttonsCount = 2
    const width = buttonsCount * theme_.authButtonSize.w + (buttonsCount - 1) * theme_.navButtonGap
    return Geo.makeSize(width, theme_.authButtonSize.h)
}

export default function AuthHeader() {
    const navigate = useNavigate()

    const [sessionUpdated, setSessionUpdated] = React.useState(false)

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const th = theme_.homePageHeaderTheme

    React.useEffect(() => {
        // const sessionListener = () => {
        //     setSessionUpdated(!sessionUpdated);
        // }
        // session_.pubsub.addListener(session_.authListenerId, sessionListener);
        // return () => { session_.pubsub.removeListener(session_.authListenerId, sessionListener) };
    }, [])

    const onMenuItem = React.useCallback((key: string) => {
        setOpenDrawer(false)
    }, [])

    const onLogout = React.useCallback(() => {
        AuthApi.logout().then(() => {
            navigate('/')
        })
    }, [])

    return (
        <>
            {th.mainMenu.show && (
                <Button onClick={() => setOpenDrawer(!openDrawer)} sx={th.mainMenu.sxProps()}>
                    <MenuIcon
                        sx={{
                            //color: theme_.navButtonTextColor,
                            width: th.mainMenu.width,
                            height: th.mainMenu.height,
                        }}
                    />
                </Button>
            )}

            {th.loginButton.show && (
                <AuthButton label='Login' href='/login' sx={th.loginButton.sxProps()} />
            )}
            {th.joinButton.show && (
                <AuthButton label='Join' href='/join' sx={th.joinButton.sxProps()} />
            )}

            {th.logoutButton.show && (
                <AuthButton
                    label='Logout'
                    onClick={() => onLogout()}
                    sx={th.logoutButton.sxProps()}
                />
            )}
            {th.greetingButton.show && (
                <AuthButton
                    tlabel={session_.greeting}
                    onClick={() => setOpenDrawer(true)}
                    sx={th.greetingButton.sxProps()}
                />
            )}

            <PageMainMenu
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onSelected={onMenuItem}
            />
        </>
    )

    // let width = props.sx?.width ?? theme_.authButtonSize.w;
    // let height = props.sx?.height ?? theme_.authButtonSize.h;
    /*
    return <Box sx={[...makeSxProps(props.sx), {}]}>

        {Env.isMobile ? <>
            <Button onClick={() => setOpenDrawer(!openDrawer)} sx={{
                p: 0,
                // width: 1,
                // height: 1,
            }}><MenuIcon sx={{width: props.width, height: props.height}}/></Button>
        </> : <Stack direction='row' spacing={1}>

            {session_.isLoggedIn ? <>
                <AuthButton tlabel={session_.greeting} onClick={() => setOpenDrawer(true)} width={theme_.authGreetingWidth}/>
                <AuthButton label='Logout' onClick={() => onLogout()}/>
            </> : <>
                <AuthButton label='Join' href='/join'/>
                <AuthButton label='Login' href='/login'/>
            </>}

        </Stack>}

        <PageMainMenu open={openDrawer} onClose={() => setOpenDrawer(false)} onSelected={onMenuItem}/>

    </Box>;
    */
}
