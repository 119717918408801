import React from 'react'

import {Box, Drawer, Divider, List, ListItem, ListItemButton, ListItemText} from '@mui/material'

import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import * as AuthApi from 'api/auth'
import session_ from 'api/session'
import Env from 'env'
import LangSelector from './LangSelector'

interface MenuProps {
    open: boolean
    onSelected: (key: string) => void
    onClose: () => void
}

export default function PageMainMenu(props: MenuProps) {
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [showLang, setShowLang] = React.useState(false)
    //const langItemRef = React.useRef(null);
    const langItemRef = React.createRef()

    const onClose = React.useCallback(() => {
        setShowLang(false)
        props.onClose()
    }, [])

    const onItemSelected = React.useCallback((key: string) => {
        setShowLang(false)

        switch (key) {
            case 'logout':
                props.onSelected(key)

                AuthApi.logout().then(() => {
                    navigate('/')
                })
                break

            case 'lang':
                setShowLang(true)
                break

            default:
                props.onSelected(key)
                //setShowLang(false);
                break
        }
    }, [])

    const drawerWidth = Env.isMobile ? 200 : 250

    // When the user is not logged in there isn't much to show
    if (!session_.isLoggedIn) {
        return (
            <Drawer open={props.open} anchor='right' onClose={onClose}>
                <Box sx={{width: drawerWidth}}>
                    <List>
                        <PageMenuItem
                            itemKey='login'
                            label='Login'
                            onSelected={(key) => onItemSelected(key)}
                            href='/login'
                        />
                    </List>

                    <Divider />
                    
                    <List>
                        <PageMenuItem
                            itemKey='tools'
                            label='Tools'
                            onSelected={(key) => onItemSelected(key)}
                            href='/tools'
                        />
                    </List>

                    <Divider />
                    <List>
                        <PageMenuItem
                            itemKey='contact'
                            label='Contact Us'
                            onSelected={(key) => onItemSelected(key)}
                        />
                    </List>
                </Box>
            </Drawer>
        )
    }

    return (
        <Drawer open={props.open} anchor='right' onClose={onClose}>
            <Box sx={{width: drawerWidth}}>
                <List>
                    <PageMenuItem
                        itemKey='profile'
                        label='Profile'
                        onSelected={(key) => onItemSelected(key)}
                        href='/profile'
                    />
                </List>

                {Env.isMobile && (
                    <>
                        <Divider />
                        <PageMenuItem
                            itemKey='scans'
                            label='Scans'
                            onSelected={(key) => onItemSelected(key)}
                            href='/scans'
                        />
                        <PageMenuItem
                            itemKey='contact'
                            label='Contact Us'
                            onSelected={(key) => onItemSelected(key)}
                            href='/contact'
                        />

                        <PageMenuItem
                            itemId='menuLangId'
                            anchorRef={langItemRef}
                            itemKey='lang'
                            label='Language'
                            onSelected={(key) => onItemSelected(key)}
                        />
                        <LangSelector
                            type='popper'
                            show={showLang}
                            anchorRef={langItemRef}
                            anchorId='menuLangId'
                        />
                    </>
                )}

                <PageMenuItem
                    itemKey='patients'
                    label='Patients'
                    onSelected={(key) => onItemSelected(key)}
                    href='/patients'
                />

                {(session_.isAdmin || session_.isDeveloper) && (
                    <>
                        <Divider />
                        {session_.isAdmin && (
                            <PageMenuItem
                                itemKey='admin'
                                label='Admin'
                                onSelected={(key) => onItemSelected(key)}
                                href='/admin'
                            />
                        )}
                        {session_.isDeveloper && (
                            <PageMenuItem
                                itemKey='dev'
                                label='Dev'
                                onSelected={(key) => onItemSelected(key)}
                                href='/dev'
                            />
                        )}
                    </>
                )}

                <Divider />
                <List>
                    <PageMenuItem
                        itemKey='logout'
                        label='Logout'
                        onSelected={(key) => onItemSelected(key)}
                    />
                </List>
            </Box>
        </Drawer>
    )
}

interface ItemProps {
    itemKey: string
    label: string
    //menu: MenuProps;
    onSelected: (key: string) => void
    href?: string
    anchorRef?: any
    itemId?: string
}

function PageMenuItem(props: ItemProps) {
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [label, setLabel] = React.useState(t(props.label) as string)

    const onSelected = React.useCallback(() => {
        props.onSelected(props.itemKey)

        if (props.href) {
            navigate(props.href)
        }
    }, [])

    return (
        <ListItem id={props.itemId} ref={props.anchorRef} button key={props.itemKey}>
            <ListItemButton onClick={onSelected}>
                <ListItemText>{label}</ListItemText>
                {/* TODO: Fix new React type error */}
            </ListItemButton>
        </ListItem>
    )
}
