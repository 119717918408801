import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {createRoot} from 'react-dom/client'

import {GlobalStyles} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'

import globalStyles from 'theme/GlobalStyle'

import '@fontsource/roboto'

import './i18n'
import './api/session'

import * as BrowserPolifylls from 'lib/polifills'
//import * as ServiceWorker from "serviceWorker"

import muiTheme from 'theme/MuiTheme'

import HomePage from 'pages/home/HomePageDarkAi/HomePageDarkAi'
import ContactUsPage from 'pages/about/ContactUsPage'

import NoMatch from 'pages/404'
import ToolsPage from 'pages/tools/ToolsPage'
import RcbcPage from 'pages/tools/RcbcPage'

// Disable right click menu
document.oncontextmenu = function () {
    return false
}

BrowserPolifylls.fix()

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <ThemeProvider theme={muiTheme}>
        {inputGlobalStyles}
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<ToolsPage />} />

                <Route path='/tools' element={<ToolsPage />} />
                <Route path='/tools/rcbc' element={<RcbcPage />} />

                <Route path='/contact' element={<ContactUsPage />} />

                <Route path='*' element={<NoMatch />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
)

