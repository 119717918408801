import React from 'react'

import styled from 'styled-components';

//import { Box } from '@mui/material';

import { DomSub } from "ui/DomLib";
import * as GD from 'data/GlobalDispatch';


import theme_ from 'theme/CurrentTheme';
import PageImage from './PageImage';

interface PageContainerProps {
    children: React.ReactNode;
};

class PageState {
    renderCount = 0;

    domSub = new DomSub();

    constructor() {
        //console.debug(document.documentElement.clientWidth);

        theme_.processWindowResize();
    }

    release() {
        this.domSub.release();
    }

}

export function PageContainer(props: PageContainerProps) {
    //console.debug(document.documentElement.clientWidth);
    const stateRef = React.useRef(new PageState());
    const st = stateRef.current!;

    const [renderCount, setRenderCount] = React.useState(0);

    React.useEffect(() => {

        const onWindowResize = () => {
            if (theme_.processWindowResize()) {
                setRenderCount(++st.renderCount);
            }
        }

        st.domSub.addWindowListener('resize', onWindowResize);

        const sessionListener = () => {
            if (theme_.processWindowResize()) {
                setRenderCount(++st.renderCount);
            }
            //setSessionUpdated(!sessionUpdated);
        }

        GD.pubsub_.addListener(GD.authListenerId, sessionListener);


        // const langListener = () => {
        //     console.debug('lang changed: ', session_.lang);

        //     setRenderCount(++st.renderCount);
        // }
        // GD.pubsub_.addListener(GD.langListenerId, langListener);


        //return () => { session_.pubsub.removeListener(session_.listenerId, sessionListener) };


        return () => { 
            GD.pubsub_.removeListener(GD.authListenerId, sessionListener);
            //GD.pubsub_.removeListener(GD.langListenerId, langListener);
            st.release() 
        };
    }, []);


    return <MainContainer>
        <HomePageDecor/>
        {props.children}
    </MainContainer>;
}

const MainContainer = styled('div')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

    //width: '100%',
    // height: '100%',
    minWidth: theme_.pageMinWidth,
    minHeight: theme_.pageMinHeight,

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundImage: theme_.homeBackgroundImage ? `url(${theme_.homeBackgroundImage})` : undefined,

    backgroundColor: theme_.pageBackgroundColor,
});


function HomePageDecor() {
    //console.debug(theme_.homePageHeaderTheme.backgroundDecor);

    return <>
        {theme_.homePageHeaderTheme.backgroundDecor.map((item, index) =>
            <PageImage key={index} show={item.show} width={item.width} height={item.height} src={item.src} sx={item.sxProps()}/>
        )}
    </>;
}

