import React from 'react';
import {Alert, Box, Container, Grid, Typography} from '@mui/material';

import * as FT from '../FormTypes';

import {useTranslation} from 'react-i18next';

interface Props extends FT.FormProps {
}

export default function FormPageTitleImpl(props: Props) {
    const [t] = useTranslation();

    if (!props.title)
        return <></>;

    return <Typography 
        align='center'
        width='100%'
        fontSize='xx-large'
    >
        {t(props.title)}
    </Typography>;
}
