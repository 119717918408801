import { makeError } from 'lib/error';
import { PubSub } from 'lib/pubsub';
import * as FT from './FormTypes';

export interface FormContextProps {
    type: 'input' | 'update'; //FT.FormType;
    //loading?: boolean;
}

export class InputFormContext {
    formIndex: number;
    formId: string;
    formType: FT.FormType;

    // Options
    formMargin = 0;
    formPadding = 1;
    gridSpacing = 1;
    gridMargin = 0; //1;


    // Loading state
    dataPending = false;
    dataRequested = false;

    // Error and state
    error?: Error;
    messageSeverity: 'error' | 'success' | 'warning' = 'success';
    messageText?: string;


    // Initialization
    constructor(formId: number, props: FormContextProps) {
        this.formIndex = formId;
        this.formType = props.type === 'input' ? FT.FormType.Input : FT.FormType.Update;
        this.rerenderListenerId = `formContextRenderListenerId_${this.formIndex}`;
        this.formId = `form_${this.formIndex}`;

        //if (props?.loading) {
        if (this.formType === FT.FormType.Update) {
            this.isLoading = true;
            this.isBusy = true;
        }

        //this.pubsub.
    }

    release() {
        this.reset();
        this.pubsub.release();
    }


    clearError() { 
        this.error = undefined;

        this.triggerRerender();
    }

    setError(err: Error | string) {
        this.isLoading = false;
        this.isBusy = false;
        this.error = makeError(err);

        this.triggerRerender();
    }

    //
    // Message
    //
    clearMessage() {
        this.messageText = undefined;
        this.triggerRerender();
    }

    setMessageUpdateSuccess() {
        this.messageText = "Updated.";
        this.messageSeverity = 'success';
        this.clearBusy();
        //this.triggerRerender();
    }


    //
    // Loading/Busy state
    //
    isLoading = false;
    isBusy = false;

    setBusy() {
        this.isBusy = true;
        this.error = undefined;
        this.messageText = undefined;

        this.triggerRerender();
    }
    clearBusy() { 
        //this.setBusy(false); 
        this.isBusy = false;
        this.triggerRerender();
    }

    reloadData() {
        this.dataPending = true;
        this.dataRequested = false;
    }

    setLoading() {
        this.isLoading = true;
        this.setBusy();
    }
    clearLoading() { 
        this.isLoading = false;
        this.isBusy = false;
        this.error = undefined;

        this.triggerRerender();
    }

    //
    // Registerd input fields
    //
    registeredFields = new Map<string, FT.InputFormField>();

    registerField(id: string, name: string, type: FT.InputFieldType, props: FT.DataFieldsProps): FT.InputFormField | null {
        
        if (this.registeredFields.has(id)) {
            this.setError(`Input with such id already exists: ${id}`);
            return null;
            //throw new Error(`Input with such id already exists: ${id}`);
        }

        let field = new FT.InputFormField(id, name, type);
        this.registeredFields.set(id, field);

        field.value = props.value;

        if (props.required)
            field.required = true;
        if (props.validate !== undefined && props.validate !== 'off')
            field.validate = props.validate;
        if (props.data !== undefined && props.data === false)
            field.isData = false;

        return field;
    }

    reset() {
        this.registeredFields.clear();
    }

    //
    // Dealing with React hooks
    //
    rerenderListenerId: string;
    pubsub = new PubSub();

    private triggerRerender() {
        this.pubsub.dispatch(this.rerenderListenerId);
    }

};
// export type InputFormStateValue = {
//     state: InputFormContext;
//     setState: React.Dispatch<React.SetStateAction<InputFormContext>>;
// };


// export const InputFormStateDefValue: InputFormStateValue = {
//     state: new InputFormContext(),
//     setState: state => {}
// };

// export const InputFormStateInstance = React.createContext(InputFormStateDefValue);

