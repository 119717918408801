import React from 'react';
import { FormContextProps, InputFormContext } from './FormContext';

var currentFormId = 1;

export function useUpdateForm() {
    return useForm({type: 'update'});
}

export function useInputForm() {
    return useForm({type: 'input'});
}

export function useForm(props: FormContextProps)
{
    const formRef = React.useRef<InputFormContext | null>(null);
    if (!formRef.current) {
        formRef.current = new InputFormContext(currentFormId++, props);
    }
    const form = formRef.current;

    //const [form] = React.useState(new InputFormContext(currentFormId++, props));

    //const [renderCount, setRenderCount] = React.useState(0);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

    React.useEffect(() => {
        const onRerender = () => {
            //setRenderCount(renderCount + 1);
            forceUpdate();
        };

        form.pubsub.addListener(form.rerenderListenerId, onRerender);

        return () => {form.release()};
    }, []);

    return form;
}

