import session_, { ApiSession } from 'api/session';
import * as Api from './api'

// export class UserLoginData {
//     token?: string;

//     userId?: string;
//     firstName?: string;
//     lastName?: string;
// }


export function login(data: Record<string, string>): Promise<ApiSession>
{
    // let login = data.login;
    // let password = data.password;

    return new Promise((resolve, reject) => {
        //Api.requestShared<ApiSession>('user', 'login', {
            // login: login, 
            // password: password
        //}).then(sess => {
        Api.requestShared<ApiSession>('user', 'login', data).then(sess => {
            //console.debug(sess);
            session_.authenticate(sess);
            resolve(sess);
        }).catch(reject);
    });
}

export function authenticate(): Promise<ApiSession>
{
    return new Promise((resolve, reject) => {
        Api.requestSession<ApiSession>('user', 'authenticate').then(sess => {
            session_.authenticate(sess);
            resolve(sess);
        }).catch(reject);
    });
}

export function logout(): Promise<void> {

    return new Promise((resolve, reject) => {
        Api.requestSession('user', 'logout');
        session_.logout();
        resolve();
    });
}



export function join(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        // console.log('i am here in auth.ts0')
        // console.log(data)

        Api.requestShared<ApiSession>('user', 'join', data).then(sess => {
            //console.log('i am here in auth.ts')
            //session_.authenticate(sess);
            resolve(sess);
            
        }).catch(reject);
    });
}

