import React from "react";

import styled from 'styled-components';

import { Box } from '@mui/material';

import { DomSub } from "ui/DomLib";


import theme_ from 'theme/CurrentTheme';
import { PageContainer } from "components/PageContainer";
import PageHeader from "./PageHeader";


interface PageContentsProps {
    type?: 'data';  // Contents type
    title?: string;
    current?: string;

    children: React.ReactNode;
};

export const PageContentsId = 'PageContentsId';

class PageState {

    domSub = new DomSub();

    release() {
        this.domSub.release();
    }
};



export default function PageContents(props: PageContentsProps) {

    const stateRef = React.useRef(new PageState());

    const [_, forceUpdate] = React.useReducer(x => x + 1, 0);

    React.useEffect(() => {
        const onWindowResize = () => {
            forceUpdate();
        }

        stateRef.current.domSub.addWindowListener('resize', onWindowResize);

        return (() => stateRef.current.release());
    }, []);



    // TODO: make it better
    if (props.type === 'data') {
        return <PageContainer>
            <PageHeader title={props.title} current={props.current} />
            <ContentsContainer>
                <ContentsData>{props.children}</ContentsData>
            </ContentsContainer>
        </PageContainer>;
    }


    return <PageContainer>
        <PageHeader title={props.title} current={props.current} />
        <ContentsContainer>{props.children}</ContentsContainer>
    </PageContainer>;
}


const ContentsContainer = styled.div({
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme_.pageHeaderHeight,
    bottom: 0,
    paddingLeft: theme_.pageHorzMargin,
    paddingRight: theme_.pageHorzMargin,
    paddingTop: theme_.pageTopMargin,
    paddingBottom: theme_.pageBottomMargin,
});


const ContentsData = styled.div({
    width: '100%',
    height: '100%',
    backgroundColor: theme_.pageBackgroundColor,
    ...theme_.cssDialogBorder(),
});
