import session_ from 'api/session'
import Env from 'env'
import * as Geo from 'lib/geo'

import {ThemeInstance} from './ThemeInstance'

export class DarkAiTheme extends ThemeInstance {
    pageLeftDecorWidth = 600
    pageRightDecorWidth = 600
    pageTopDecorAllowance = 60

    titleTop = 10
    titleLeft = Env.isMobile ? 2 : 120
    titleWidth = 400
    titleShortWidth = Env.isMobile ? 120 : 140

    authTop = 0
    authRight = 10
    authGap = 0

    constructor() {
        super()
        //console.debug("Theme constructor");

        //this.authTop = super.muiSpacing;
        //console.debug(super.muiSpacing);

        this.onInit()
    }

    override onInit() {
        //console.debug("onInit");

        //super(new ScanThemeInstance(this))
        //this.scan = new ScanThemeInstance(this);

        this.themeColor = '#25afce'
        this.errorColor = '#ff0000'

        this.authTop = this.muiSpacing
        this.authRight = this.muiSpacing
        this.authGap = this.muiSpacing

        //console.debug(this.authRight);

        this.pageBackgroundColor = '#F5F5F5' // White Smoke
        // this.pageBackgroundColor = '#FFF8DC';  // Corn silk
        //pageTextColor = '#2d2d30';  // VS Code dark theme color
        //this.pageTextColor = '#1F3F3f';  // VS Code dark theme color
        this.pageTextColor = '#f5f5f5' // VS Code dark theme color

        this.homeBackgroundImage = '/images/home_ai_dark/dark_home_background.png'

        this.navButtonTextColor = '#f5f5f5'

        this.dialogBorderColor = this.themeColor
        this.dialogBackgroundColor = '#ffffff'
        this.dialogTextColor = '#000000'

        //this.dialogTextColor = '#dbf8ff';

        this.dropZoneBorder.color = '#80808088'
        this.dropZoneOverBorder.color = '#f0f0f0'

        // Initialize theme decors
        let imagesRoot = '/images/home_ai_dark/'
        let th = this.homePageHeaderTheme

        th.bkTopLeftDecor.init({
            show: !Env.isMobile,
            position: 'absolute',
            width: this.pageLeftDecorWidth,
            left: 0,
            top: 0,
            src: imagesRoot + 'dark_home_top_left_decor.png',
        })
        th.backgroundDecor.push(th.bkTopLeftDecor)

        th.bkTopRightDecor.init({
            show: !Env.isMobile,
            position: 'absolute',
            width: this.pageRightDecorWidth,
            right: 0,
            top: 0,
            src: imagesRoot + 'dark_home_top_right_decor.png',
        })
        th.backgroundDecor.push(th.bkTopRightDecor)

        th.logo.init({
            show: false,
            position: 'absolute',
            left: 0,
            top: 0,
            width: 64,
            height: 64,
            src: '/images/empty_lens_logo.svg',
        })

        th.titleBanner.init({
            show: !Env.isMobile,
            position: 'absolute',
            left: this.titleLeft,
            top: this.titleTop,
            width: this.titleWidth,
            src: imagesRoot + 'dark_home_title.png',
        })

        th.titleBannerShort.init({
            position: 'absolute',
            left: this.titleLeft,
            top: this.titleTop,
            width: this.titleShortWidth,
            src: imagesRoot + 'dark_home_title_short.png',
        })

        th.mainMenu.init({
            show: Env.isMobile,
            position: 'absolute',
            right: 0,
            width: this.pageHeaderHeight,
            height: this.pageHeaderHeight,
        })

        th.loginButton.init({
            show: !Env.isMobile,
            position: 'absolute',
            right: this.authRight,
            top: this.authTop,
            //width: this.authButtonSize.w,
            width: this.authGreetingWidth,
            height: this.authButtonSize.h,
            //fontSize: this.authButtonFontSize,
        })

        th.logoutButton.init({
            show: !Env.isMobile,
            position: 'absolute',
            right: this.authRight,
            top: this.authTop,
            width: this.authButtonSize.w,
            height: this.authButtonSize.h,
        })

        th.joinButton.init({
            show: !Env.isMobile,
            position: 'absolute',
            right: th.logoutButton.right! + this.authGap + th.logoutButton.width!,
            top: this.authTop,
            width: this.authButtonSize.w,
            height: this.authButtonSize.h,
        })

        th.greetingButton.init({
            show: !Env.isMobile,
            position: 'absolute',
            //right: th.joinButton.right,
            right: th.logoutButton.right,
            top: this.authTop,
            width: this.authGreetingWidth,
            height: this.authButtonSize.h,
        })

        //const langSelectorWidth = 100; //this.authButtonSize.w;
        const langSelectorSize = new Geo.Size(100, 28)
        //const langGap = 20;

        th.langSelector.init({
            show: true, //!Env.isMobile,
            position: 'absolute',
            top: this.authTop + (this.authButtonSize.h - langSelectorSize.h) / 2,
            width: langSelectorSize.w,
            height: langSelectorSize.h,
        })

        //
        // Navigation Bar
        //
        th.navBar.init({
            show: !Env.isMobile,
            position: 'absolute',
            height: this.navButtonSize.h,
        })
    }

    override onWindowResize(): boolean {
        return this.calcHeaderLayout()
    }

    calcHeaderLayout() {
        let clientWidth = document.documentElement.clientWidth

        if (clientWidth < this.pageMinWidth) clientWidth = this.pageMinWidth

        let th = this.homePageHeaderTheme

        //
        // User authentication section
        //
        if (!Env.isMobile) {
            let authWidth = 0 //this.authGap;

            if (!session_.isLoggedIn) {
                th.loginButton.show = true
                //authWidth += th.loginButton.width!;
                authWidth += th.greetingButton.width!

                th.joinButton.show = false
                //authWidth += this.authGap + th.joinButton.width!;

                th.logoutButton.show = false
                th.greetingButton.show = false
            } else {
                th.logoutButton.show = false // true;
                //authWidth += th.loginButton.width! + this.authGap;

                th.greetingButton.show = true
                authWidth += th.greetingButton.width!

                th.loginButton.show = false
                th.joinButton.show = false
            }

            //
            // Lang selector
            //
            th.langSelector.right = this.authRight + authWidth + this.authGap * 2

            // if (session_.isLoggedIn) {
            //     th.langSelector.show = false;
            //     th.langSelector.right = th.greetingButton.right;
            //     th.langSelector.width = th.greetingButton.width;
            // }
        } else {
            //
            // Lang selector
            //
            th.langSelector.top = 16
            th.langSelector.right = 80
        }

        //th.navBar.show = !Env.isMobile;

        //
        // Title Banner Layout
        //
        if (!Env.isMobile) {
            let langSelectorLeft = clientWidth - (th.langSelector.right! + th.langSelector.width!)
            // if (!th.langSelector.show)
            //     langSelectorLeft = th.

            let bannerRight = th.titleBanner.left! + th.titleBanner.width!
            let shortBannerRight = th.titleBanner.left! + th.titleBannerShort.width!

            if (langSelectorLeft < bannerRight) {
                th.titleBanner.show = false

                if (langSelectorLeft < shortBannerRight) {
                    th.titleBannerShort.show = false
                } else {
                    th.titleBannerShort.show = true
                }
            } else {
                th.titleBanner.show = true
                th.titleBannerShort.show = false
            }
        }

        //
        // Navigation bar
        //
        if (!Env.isMobile) {
            let navButtonCount = 3 //session_.isLoggedIn ? 3 : 2;
            let navBarWidth =
                this.navButtonSize.w * navButtonCount + this.navButtonGap * (navButtonCount - 1)

            let availSpace = clientWidth - this.pageLeftDecorWidth - this.pageRightDecorWidth
            availSpace += this.pageTopDecorAllowance * 2
            //console.debug(availSpace);

            th.navBar.right = (clientWidth - navBarWidth) / 2
            //th.navBar.right
            th.navBar.width = navBarWidth
            th.navBar.top = this.pageHeaderHeight - this.navButtonSize.h - 6

            th.bkTopRightDecor.show = true
            //th.banner.show = true;

            if (!isNaN(availSpace)) {
                if (availSpace > navBarWidth) {
                    th.navBar.top = 20 //this.pageHeaderHeight - this.navButtonSize.h;
                } else {
                    if (availSpace < 0) {
                        //console.debug(availSpace);
                        th.bkTopRightDecor.show = false
                        th.navBar.right = this.authRight
                        //th.navBar.left = undefined;

                        //th.banner.show = false;
                    }
                }
            }
        }

        return true
    }
}
